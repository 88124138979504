#property--box-container {
  width: 100%;
  position: relative;
  min-height: 100vh;
  background-size: cover;
  display: none;
}

#property--box-container.loaded {
  display: block;
}

.property--box-color {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.commercial-h4-text {
  color: #ffd800;
  font-size: 12px;
  padding: 0;
  margin: 0;
  line-height: 20px;
  letter-spacing: 3px;
  padding-top: 70px !important;
}
.commercial-h1-text {
  color: #fff;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 25px;
  font-size: 25px !important;
}

.property-input-box {
  padding-top: 30px !important;
  min-height: 400px !important;
}

.property-input-box-background {
  background-color: #fff;
  padding: 28px 42px 44px 42px;
  width: 100% !important;
  height: 100% !important;
}

.property-search-label {
  color: #5c0282;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 27px;
  margin-bottom: 7px;
}

.property-active-tab {
  color: rgba(0, 0, 0, 0.8);
}

.property-tab {
  color: rgba(0, 0, 0, 0.4);
}
.property-tab:hover {
  color: rgba(0, 0, 0, 0.8);
}

.custom-select {
  background-color: white;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 17px !important;
}
.custom-select:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0.6) !important;
}

.property-btn {
  margin-top: 20px;
  background: transparent !important;

  border: 1px solid #5c0282 !important;
  color: #5c0282 !important;

  width: 100%;

  padding: 15px !important;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: all 0.2s;
}

.property-btn:hover {
  background-color: #5c0282 !important;
  color: #fff !important;
}

.property-range {
  margin-top: 10px !important;
}

@media (max-width: 1024px) {
  #property--box-container {
    width: 100%;
    height: 100%;
    min-height: 700px;
  }
}

@media (min-width: 768px) {
  .commercial-h1-text {
    font-size: 30px !important;
    line-height: 30px;
  }
  .commercial-h4-text {
    padding-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .commercial-h1-text {
    font-size: 46px !important;
    line-height: 50px;
  }
  .property-input-box {
    min-height: 490px !important;
  }
  .property-btn {
    margin-top: 40px;
  }
}

@media (min-width: 1399px) {
  .property-input-box {
    min-height: 523px !important;
  }
}
