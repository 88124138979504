.container-about {
  margin-top: 140px;
  margin-bottom: 50px;
}

.container-about h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #5c0282;
  margin-top: 20px;
}

.container-about h2 {
  text-align: center;
  margin: 20px 0;
}

.container-about div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-about img {
  margin: 30px 0;
  max-width: 90%;
  object-fit: contain;
}

.container-about p {
  text-indent: 2em;
}
