.contact-img-box {
  margin-top: 100px !important;
  margin-bottom: 50px;
}

.contact-img-box img {
  max-width: 100% !important;
  object-fit: cover;
}
.contact-form {
  padding: 25% 5% 20% 5%;
}

.contact-form h1 {
  font-size: 30px;
  line-height: 40px;
}

.contact-form p {
  margin-bottom: 100px;
  font-size: 17px;
}

.contact-form-box {
  display: flex;
  flex-direction: column;
}

.contact-form-box label {
  color: #5c0282;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
}

.contact-form-box input {
  background-color: white;
  border-bottom: 1px solid #aaa;
  border-radius: 0;
  margin-top: 6px;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  padding-left: 0;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.contact-form-box textarea {
  text-decoration: none;
}

.contact-form-box input:focus {
  border-bottom-color: #caf0f8;
  outline: none;
}

.contact-form-box button {
  background-color: #5c0282;
  color: #fff;
  border: solid 1px #5c0282;
  width: 100%;
  border-radius: 3px;
  padding: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 40px;
  text-decoration: none;
}

.contact-form-box button:active,
.contact-form-box button:hover {
  background-color: #fff;
  color: #5c0282;
}

@media (max-width: 576px) {
  .contact-img-box img {
    max-width: 100%;
  }
  .contact-title {
    font-size: 22px !important;
  }
}

@media (max-width: 972px) {
  .contact-form {
    padding: 5%;
  }
}
