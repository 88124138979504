.team {
  margin: 150px 0 100px 0;
  min-height: 464px !important;
}

.team-title {
  font-size: 36px;
  margin-bottom: 20px;
  color: #5c0282;
  opacity: 0.9 !important;
}

.team h4 {
  font-size: 12px;
  font-weight: 400 !important;
  color: #5c0282;
  letter-spacing: 2px;
  margin-top: -15px;
  margin-bottom: 40px;
  line-height: 16px;
  opacity: 0.9 !important;
}

.team-card {
  height: 360px !important;
}

.team-card img {
  width: 100%;
  height: 270px !important;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
  margin-bottom: 20px;

  object-position: top;
}

.team-card-title {
  font-size: 20px;
  color: #5c0282;
  line-height: 22px;
  margin-bottom: 10px;
  opacity: 0.9 !important;
}

.team-card strong {
  color: #020202 !important;
}

.team-card a {
  list-style: none;

  text-decoration: none;
}

.member-data-box img {
  width: 100%;
  height: 270px !important;
  object-fit: cover;
}

.member-data-box p {
  color: rgba(0, 0, 0, 0.9) !important;
}

.member-name {
  margin-top: 20px;
  font-size: 34px;
  font-weight: 400;
  margin-bottom: 4px;
  color: #5c0282;
  opacity: 0.9 !important;

  line-height: 42px;
}

.member-overview {
  color: rgba(0, 0, 0, 0.5) !important;
}

.staff-card {
  background-color: #fff;
  padding: 25px 30px 34px 30px;
  border-radius: 7px;
  box-shadow: 0 1px 12px #dadada;
}

.staff-card h2 {
  color: #000 !important;
  opacity: 0.9 !important;
  font-size: 24px;
  margin-bottom: 25px;
}

.staff-phone h5 {
  font-size: 10px !important;
  color: #5c0282;
  opacity: 0.9 !important;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.staff-card a {
  text-decoration: none;
  color: #000 !important;
  opacity: 0.9 !important;
}

.staff-card a:hover {
  color: #5c0282 !important;
}

.staff-stats {
  background-color: #5c0282;
  border-radius: 7px;
  margin-top: 20px !important;
  color: #fff;
}
.staff-stats h5 {
  font-size: 16px;

  padding: 20px 30px;
  border-bottom: solid 1px #9255ab;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.staff-numbers-card {
  padding: 13px 30px 10px 30px;
  overflow: hidden;
}

.staff-numbers {
  font-size: 32px;
  color: #ffd82e;
}

.staff-property {
  margin-top: 50px !important;
  margin-bottom: 40px !important;
}

.staff-property h4 {
  font-size: 12px;
  font-weight: 400 !important;
  color: #5c0282;
  letter-spacing: 2px;
  padding: 0;
  margin: 0;
  line-height: 16px;
}

.staff-property h2 {
  font-size: 36px;
  color: #1f1f1f;
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: 43px;
}

.property-card-member {
  width: calc(33.33% - 20px) !important;
  margin: 0 auto;
  padding-top: 10px;
}

.property-card-member h4 {
  color: #fff;
}

.caurosel-prev-icon .carousel-control-prev {
  left: auto !important;
  right: 65px !important;
}

.team .caurosel-prev-icon .carousel-control-prev,
.team .caurosel-prev-icon .carousel-control-next {
  color: rgba(0, 0, 0, 0.9) !important;
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.4);
  transition: all 0.2s;
}

.team .caurosel-prev-icon .carousel-control-prev:hover,
.team .caurosel-prev-icon .carousel-control-next:hover {
  color: #fff !important;
  background-color: #5c0282 !important;

  border: none;
}

@media (max-width: 1024px) {
  .property-card-member {
    width: 85% !important;
  }
}

@media (max-width: 991px) {
  .team {
    margin: 100px 0 50px 0 !important;
  }

  .team-card {
    height: 510px !important;
  }

  .team-card img {
    height: 340px !important;
  }

  .member-name {
    font-size: 20px;
  }

  .staff-card h2 {
    margin-bottom: 0px;
  }

  .staff-property h2 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .staff-property {
    margin-bottom: 20px !important;
  }

  .property-card-member {
    width: calc(100% - 30px) !important;
  }
}

@media (max-width: 576px) {
  .staff-property h2 {
    font-size: 16px;
  }

  .caurosel-prev-icon .carousel-control-prev {
    right: 55px !important;
  }
  .caurosel-prev-icon .carousel-control-next {
    right: 10px;
  }

  .team-card {
    height: 460px !important;
  }

  .team-card img {
    height: 300px !important;
  }
}
