.property-details {
  margin: 130px 0 65px 0 !important;
}

.carousel-property-card > .carousel a {
  display: none !important;
}

.carousel-property-card {
  position: relative;
}
.carousel-property-card img {
  max-height: 600px;
}
.carousel-controls {
  padding: 0 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.carousel-controls button {
  box-shadow: 1px 1px 1px #ccc;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  color: #212529 !important;
  margin-top: 10px;
  border: 1px solid #fff;
  z-index: 10000;
  opacity: 1 !important;
}

.disabled {
  border: 1px solid #d6d6d6 !important;
  background-color: #d6d6d6 !important;
}

.user-property-card {
  max-height: 600px;
  background-color: #fff;
  padding: 44px 30px 34px 25px;
  border-radius: 7px;
  box-shadow: 0 1px 12px #dadada;
}

.property-number {
  padding: 0px;
  margin: 0 0 10px 0;
  color: #5c0282;
  font-size: 10px;
  letter-spacing: 1px;
}

.property-details-title {
  color: #000;
  font-size: 24px;
  font-family: "Circular Std";
  margin-bottom: 12px;
}

.property-size-price-title {
  font-size: 12px;
  color: #272727;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 5px 0 5px 0;
}
.property-size-price {
  font-size: 28px !important;
  color: #5c0282 !important;
  font-weight: 400;
  padding-bottom: 0px;
}

.agent-card-details {
  background-color: #5c0282;
  border-radius: 7px;
  padding: 30px 20px;
  margin-top: 15px !important;
}

.agent-card-details a {
  list-style: none;
  text-decoration: none;
}

.agent-card-details h5 {
  font-size: 10px;
  color: #fff;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.search-rent-sale {
  margin: 100px 0 10px 0;
}

.agent-photo {
  width: 53px;
  height: 53px;
  border-radius: 50%;
}

.agent-photo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.agent-name-language {
  width: 80%;
}

.agent-name-language h3,
.agent-name-language h4 {
  font-size: 15px;
  color: #fff;
  padding-left: 10px;
  font-weight: 800;
}

.agent-phone-email-container {
  margin-top: 30px;
}

.phone-email-icon {
  color: #5c0282;
}

.agent-phone-email a {
  list-style: none;
  color: #5c0282;
  text-decoration: none;
}

.no-search {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-to-home {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #5c0282;
  text-decoration: none;
  list-style: none;
}
