.commercial {
  position: relative;
  background-position: center;
  background-size: cover;
  padding-top: 50px !important;
  padding-bottom: 80px;
  background-color: #f5f5f5;
}

.commercial a {
  text-decoration: none;
}

.commercial-big-title {
  font-size: 36px;
  color: rgba(0, 0, 0, 0.9);
  padding-top: 20px;
  margin: 0;
  font-weight: 400;
  line-height: 43px;
  text-align: center !important;
  margin-bottom: 15px !important;
}

.commercial-item {
  position: relative;
  min-height: 230px;
  background-color: transparent;

  padding: 0 5px;
  text-align: center !important;
  border: 3px solid rgba(0, 0, 0, 0.4);
  margin: 10px 0;
}

.commercial-item figure {
  height: 131px;
}

.commercial-item img {
  max-width: 100%;
  opacity: 0.8;
}

.commercial-item h5 {
  font-size: 17px;
  color: #5c0282;
  opacity: 0.8;

  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 20px;
}

.commercial-item p {
  font-size: 28px;
  color: rgba(0, 0, 0, 0.9);
  padding-top: 5px;
  margin: 0;
}

@media (max-width: 768px) {
  .commercial .col {
    flex: 1 0 50%;
  }
  .commercial {
    padding-top: 30px !important;
    padding-bottom: 50px;
  }
}

@media (max-width: 560px) {
  .commercial-big-title {
    font-size: 20px;
    line-height: 25px;
  }
}
