.search-rent-sale-link a {
  list-style: none !important;
  text-decoration: none !important;
}

.search-rent-sale-card {
  margin-bottom: 30px;
  border: solid 1px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.search-rent-sale-card:hover h4 {
  background-color: #5c0282 !important;
  opacity: 0.8;
}
.search-rent-sale-card-img-box {
  width: 100% !important;
  height: 216px !important;
  position: relative !important;
}

.search-rent-sale-card img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}

.search-rent-sale-card-place {
  margin: 0 !important;
  text-align: left;
  color: #fff !important;
  font-size: 10px;
  position: absolute !important;
  background-color: rgba(0, 0, 0, 0.4);
  min-width: 111px;
  bottom: 0 !important;
  font-weight: 400;
  padding: 13px 20px 15px 20px;
  text-transform: uppercase;
}

.search-rent-sale-featured {
  padding: 10px 20px 15px 20px;
}

.search-rent-sale-title {
  padding: 0;
  margin: 10px 0 10px 0;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 18px;
}

.search-rent-sale-title span {
  font-size: 10px;
  color: #5c0282;
  opacity: 0.9;
  float: right;
  letter-spacing: 2px;
}

.search-rent-sale-price {
  font-size: 13px;
  padding: 0;
  margin: 20px 0 0 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.search-rent-sale-m {
  font-size: 13px;
  padding: 0;
  margin: 0 0 10px 0;
  font-weight: 100;
  color: rgba(0, 0, 0, 0.7);
}

.search-rent-sale-day {
  font-size: 13px;
  padding: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}
