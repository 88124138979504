.custom-cursor {
  position: fixed;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #5c0282;
  pointer-events: none !important;
  z-index: 9999;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #5c0282;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 10000;
}
