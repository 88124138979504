.about {
  position: relative;
  padding: 90px 0;
  background-color: #fff;
}

.btn-about {
  background: transparent !important;

  border: 1px solid #5c0282 !important;
  color: #5c0282 !important;

  margin-top: 10px !important;
  font-size: 12px;
  min-width: 200px;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin: 24px 12px 0 !important;
  transition: all 0.2s;
}

.btn-about:hover {
  background-color: #5c0282 !important;
  color: #fff !important;
}

.about-us {
  font-size: 12px;
  font-weight: 400 !important;
  color: #5c0282;
  letter-spacing: 2px;
  padding: 0;
  margin: 0;
  line-height: 16px;
}
.about-title {
  font-size: 36px;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.8;
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: 43px;
}

.about-left-paragraph {
  max-width: 400px;
  width: 100%;
  font-size: 16px;
  padding: 0;
  margin: 30px 0 0 0;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.4;
  font-weight: 100;
}

.right-box {
  display: flex;
}
.right-item {
  width: 50%;
  display: flex;
  padding-right: 20px;
}
.right-img-box {
  text-align: center !important;
}

.right-text-box {
  padding-left: 10px;
}
.right-title {
  font-size: 15px;
  font-weight: 400;
  color: #5c0282;
  padding: 0;
  margin: 0;
  line-height: 20px;
}
.right-text {
  font-size: 12px;
  color: #1c314e;
  opacity: 0.4;
  font-weight: 100;
  padding: 0;
  margin: 0;
  line-height: 22px;
}

@media (max-width: 768px) {
  .about {
    padding: 70px 0 50px 0;
  }

  .about-us {
    margin-bottom: 5px;
  }
  .about-title {
    font-size: 20px;
    line-height: 25px;
  }
  .about-left-paragraph {
    margin: 20px 0 0 0;
    font-size: 14px;
    line-height: 15px;
  }
  .about-right-box {
    margin-top: 8% !important;
  }
  .right-box {
    display: block !important;
  }
  .right-item {
    width: 100%;

    padding-bottom: 20px;
    padding-right: 0px;
  }

  .right-img {
    height: 40px !important;
    width: 40px !important;
  }
}

@media (max-width: 560px) {
  .btn-about {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 30px !important;
  }
}
