.footer-top {
  background-color: #333;
  padding: 50px 0 25px 0;
}

.footer-top-link-style:hover {
  color: #ffd800 !important;
  scale: 1.05 !important;
}

.footer-top-p-style:hover {
  scale: 1.05 !important;
}

.footer-logo {
  height: 120px;
}

.adress-box {
  color: #b5b5b5;
}

.adress-box a {
  list-style: none;
  color: #b5b5b5;
  text-decoration: none;
}

.footer-rent li,
.footer-rent a {
  list-style: none;
  color: #b5b5b5;
  text-decoration: none;
}

.footer-rent img {
  height: 46px !important;
  width: 56px !important;
  margin-right: 5px;
}

.footer-title {
  font-size: 24px;

  font-weight: 400;
}

.footer-bottom {
  background-color: #101010;
  padding: 20px 0;
}

.copyright {
  color: #b5b5b5;
}

.footer-social li {
  list-style: none !important ;
}

.footer-space {
  margin: 0 5px;
}

.footer-social a {
  width: 30px;
  height: 30px;
  background-color: #b5b5b5;
  border-radius: 50%;
  line-height: 32px;
  text-align: center;
  color: var(--main-color-dark) !important;
  margin: 0 4px;
  display: inline-block;
}

.footer-social li:hover {
  scale: 1.1 !important;
}

.footer-privacy {
  color: #b5b5b5;
}

.footer-privacy a {
  list-style: none;
  color: #b5b5b5;
  text-decoration: none;
}

.footer-privacy a:hover {
  color: #ffd800 !important;
}

.footer-privacy li {
  list-style: none !important ;
}

.footer-privacy li:hover {
  scale: 1.05 !important;
}

@media (max-width: 768px) {
  .footer-top {
    padding-bottom: 0 !important;
  }

  .footer-bottom {
    padding: 20px 0 5px 0;
  }

  .copyright {
    margin-bottom: 5px;
    text-align: center;
  }
  .footer-social {
    margin-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .adress-box {
    text-align: center;
  }
  .footer-title {
    font-size: 18px;
  }
  .blog-li {
    margin-bottom: 10px !important;
  }
  .blog-li img {
    margin-right: 10px !important;
  }
}

.truncate-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  padding-left: 5px;
}
