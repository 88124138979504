.featured-container {
  margin-top: 40px !important;
}

.featured {
  margin-bottom: 100px;
}

.carousel-item-property-card {
  width: calc(33.33% - 20px) !important;
  margin: 0 auto;
  padding-top: 10px;
}

.featured-card a {
  text-decoration: none;
}

.featured img {
  width: 100%;
  height: 216px !important;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}

.featured-box {
  height: 350px !important;
}

.featured .caurosel-prev-icon .carousel-control-prev,
.featured .caurosel-prev-icon .carousel-control-next {
  color: rgba(0, 0, 0, 0.9) !important;
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.4);
  transition: all 0.2s;
}

.featured .caurosel-prev-icon .carousel-control-prev:hover,
.featured .caurosel-prev-icon .carousel-control-next:hover {
  color: #fff !important;
  background-color: #5c0282 !important;

  border: none;
}

@media (max-width: 768px) {
  .featured {
    margin: 45px 0 20px 0 !important;
  }
  .featured-container {
    margin-top: 10px !important;
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .caurosel-prev-icon .carousel-control-prev,
  .caurosel-prev-icon .carousel-control-next {
    width: 50px !important;
    height: 50px !important;
    top: -100px !important;
  }
  .carousel-control-prev {
    right: 60px !important;
  }
}

@media (min-width: 992px) {
  .caurosel-prev-icon .carousel-control-next,
  .caurosel-prev-icon .carousel-control-prev {
    top: -100px !important;
  }
}

@media (max-width: 768px) {
  .carousel-item-property-card {
    width: calc(100% - 30px) !important;
  }
}

@media (max-width: 1024px) {
  .carousel-item-property-card {
    width: 85% !important;
  }
}
