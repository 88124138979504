.back-home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-transform: uppercase;
  font-size: 20px;
}

.back-home-btn {
  background: transparent !important;

  border: 1px solid #5c0282 !important;
  color: #5c0282 !important;

  margin-top: 30px !important;

  min-width: 200px;
  padding: 15px 0 !important;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  border-radius: 0 !important;
  transition: all 0.2s;
}

.back-home-link {
  color: #5c0282 !important;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 15px 30px;
}

.back-home-link:hover {
  color: #fff !important;
}

.back-home-btn:hover {
  background-color: #5c0282 !important;
}

.dashboard-menu {
  display: flex;
  flex-direction: column;
}

.dashboard-header {
  background-color: #f6f6f6;
  height: 74px;
}

.dash-header-info {
  padding-right: 10px;
  padding-top: 15px;
}

.dash-header-info p {
  font-size: 12px;
  line-height: 1.3;
}

.dashboard-header span {
  color: #000 !important;
  font-size: 18px;
}

.dashboard-header img {
  width: 45px;
  height: 45px;
}

.progressbarr-link {
  list-style: none;
  text-decoration: none;
}

.progress-bar-container {
  cursor: pointer;
  height: 180px;
  background-color: #ff6746;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 0.75rem;
  color: #fff;
  text-decoration: none;
}

.progress-bar-container h4 {
  color: #3d4465;
}

.progress-bar-container span {
  color: #7e7e7e;
}

.progress-bar-icon {
  width: 60px;
  height: 60px;
  padding-right: 2px;
  background-color: #ff6746;
  color: #fff;
}

.circular-progressbar {
  height: 90px;
  width: 90px;
}

.circular-rent h2 {
  font-size: 36px !important;
  line-height: 1.25;
  color: #000 !important;
}

.circular-rent p {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 1.5;
  color: #000 !important;
  margin-bottom: 0;
}

.circular-rent span {
  font-size: 13px !important;
  line-height: 1.4;
}
