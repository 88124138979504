.blog-container {
  min-height: 100vh;
}

.blog-cover-img-container {
  position: relative;
  overflow: hidden;
  max-height: 95vh;
}

.blog-cover-img {
  width: 100%;
  height: auto;
  display: block;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.blog-overlay-text {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: white;
  transform: translateY(-50%);
  text-align: center;
  padding: 10px;
}

.blog-overlay-text h5 {
  font-size: 10px;
  color: #ffd800;
  letter-spacing: 2px;
}

.blog-overlay-text h1 {
  font-size: 35px;
  color: #d9d6d4;
  font-weight: 400;
  margin: 20px 0;
}

.blog-overlay-text button {
  background: transparent !important;

  border: 1px solid #fff !important;
  color: #fff !important;

  margin-top: 10px !important;
  font-size: 12px;
  min-width: 200px;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin: 24px 12px 0 !important;
  transition: all 0.2s;
}

.blog-overlay-text button:hover {
  border: 1px solid #5c0282 !important;
  background-color: #5c0282 !important;
  color: #fff !important;
}

@media (max-width: 576px) {
  .blog-overlay-text {
    top: 65%;
  }

  .blog-overlay-text h5 {
    font-size: 8px;
  }
  .blog-overlay-text h1 {
    font-size: 20px;
  }
}

.blog-card-text h5 {
  font-size: 12px;
  color: #5c0282;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.blog-card-text h2 {
  font-size: 22px;
  color: #3e3e3e;
  line-height: 25px;
  margin-bottom: 15px;
  min-height: 50px;
  text-wrap: balance;
}

.blog-card-text p {
  font-size: 12px;
  color: #404040;
  padding-bottom: 15px;
}

.blog-text-container-height {
  min-height: 100vh !important;
}

.blog-title-section {
  margin-top: 130px !important;
  margin-bottom: 50px !important;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.blog-title-section h1 {
  font-size: 36px;
  color: #1f1f1f;
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: 43px;
}

.blog-title-section h4 {
  font-size: 12px;
  font-weight: 400;
  color: #5c0282;
  letter-spacing: 2px;
  padding: 0;
  margin: 0;
  line-height: 16px;
}

.blog-title-section {
  color: #272727;
  font-size: 12px;
  letter-spacing: 2px;
}

.blog-img-section img {
  min-width: 100%;
  height: 424px;
  object-fit: cover;
}

.blog-content-section {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .blog-img-section img {
    max-width: 100%;
  }
}
