.navbar--border-top {
  border-top: solid 1px #3a3a3a;
  border-bottom: solid 1px #3a3a3a;
  margin-top: 8px;
}

.navbar-padding {
  padding: 16px 0px 16px 64px !important;
  margin-left: 60px;
}
.navbar--color-white {
  color: #d3d3d3 !important;
  font-weight: 600 !important;
  margin-left: 1.5rem !important;
}

.navbar--color-white:hover {
  color: #ffd800 !important;
  scale: 1.05 !important;
}
.navbar--active-link-classes {
  color: #c0b600 !important;
}

.header-background {
  background-color: #272727 !important;
  min-height: 74px;
}
.dropdown-menu {
  background-color: #272727 !important;
}

#navbarScrollingDropdown::after {
  color: #5c0282;
}
.logo-container {
  height: 74px !important;
  position: absolute;
}

.navbar-container-small-screen {
  max-width: 100% !important;
  width: 100vw !important;
  margin-top: 9px !important;
}
.btn-home-margin {
  margin-bottom: 9px !important;
}

.navbar-container {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.navbar-login {
  margin-top: 5px;
  margin-bottom: 7px;
}

.dashboard-login-btn {
  border: 2px solid #fff !important;
  border-radius: 2px !important;
  padding: 7px 14px !important;
  margin-right: 15px;
}

.navbar-dropdown-menu {
  color: #d3d3d3 !important;
  background-color: #272727 !important;
  padding-left: 1.5rem !important;
}

.navbar-dropdown-menu:hover {
  color: #ffd800 !important;
  font-size: 1.05em;
}

.dropdown-item.active {
  color: #c0b600 !important;
}

@media (max-width: 991px) {
  .nav-link-hover-mobile:hover {
    padding-left: 1.2rem !important;
  }
}

@media (max-width: 768px) {
  .nav-link-hover-mobile:hover {
    padding-left: 0.9rem !important;
  }
}

@media (max-width: 576px) {
  .nav-link-hover-mobile:hover {
    padding-left: 0.5rem !important;
  }
}
