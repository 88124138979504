/* Reset default margin and padding */
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

/* Set box-sizing to border-box for all elements */
* {
  box-sizing: border-box;
}

/* Set a base font size and family */
body {
  font-family: "Circular Std" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

@font-face {
  font-family: "Circular Std";
  src: url("./assets/data/Apercu-Regular.woff2") format("woff2"),
    url("./assets/data/Apercu-Regular.woff2") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
