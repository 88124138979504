.backToTopButtonContainer {
  position: absolute;
  bottom: -80px;
  right: 10px;
}

.backToTopButton {
  background: transparent;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.9);
  transition: all 0.2s;
}

.backToTopButton:hover {
  background-color: #5c0282;
  color: #fff;
  border: none;
  scale: 1.1;
}
